<script>
export default {
	lang: 'account',
	metaInfo() {
		return {
			title: this.cTitle,
		}
	},
	props: {
		title: String,
	},
	data() {
		return {
			items: [
				{ title: this.$lang('Mi cuenta'), icon: 'mdi-account', attrs: { to: { name: 'user.account' } } },
				{
					title: this.$lang('Mis compras'),
					icon: 'mdi-shopping',
					attrs: { to: { name: 'user.orders' } },
				},
				{
					title: this.$lang('Mis favoritos'),
					icon: 'mdi-heart',
					attrs: { to: { name: 'user.favorites' } },
				},
				{
					title: this.$lang('Mis Reseñas'),
					icon: 'mdi-star',
					attrs: { to: { name: 'user.reviews' } },
					showIf: () => {
						const reviewsConfig = this.$srv('reviewsConfig')
						return reviewsConfig && reviewsConfig.enabled
					},
				},
				{
					title: this.$lang('Salir'),
					icon: 'mdi-account-off-outline',
					attrs: { loading: false },
					listeners: { click: () => this.logout() },
				},
			],
		}
	},
	computed: {
		cTitle() {
			return this.title || this.items.find((x) => x.attrs?.to?.name == this.$route.name)?.title || ''
		},
		visibleItems() {
			return this.items.filter((x) => !x.showIf || x.showIf())
		},
	},
	methods: {
		logout() {
			this.$shopApi.post({
				url: '/user/logout',
				loading: (v) => (this.items[3].attrs.loading = v),
				onSuccess: ({ options }) => {
					options.abort()
					window.location.href = this.$router.resolve({ name: 'home' }).href
				},
			})
		},
	},
}
</script>

<template>
	<v-container class="mb-16">
		<v-row>
			<v-col
				cols="12"
				md="3"
				class="pa-md-6 d-flex flex-row flex-wrap flex-md-column justify-center justify-md-start"
				style="gap: 12px"
			>
				<div v-for="(item, i) of visibleItems" :key="i">
					<Button
						class="justify-sm-start"
						v-bind="item.attrs"
						v-on="item.listeners"
						:block="$b.d"
						:large="$b.d"
						outlined
					>
						<v-icon class="mr-1 mr-md-4">{{ item.icon }}</v-icon>
						<div class="text-no-wrap">{{ item.title }}</div>
					</Button>
				</div>
			</v-col>
			<v-col cols="12" md="9" class="pt-5">
				<div class="text-h4 mb-4">
					{{ cTitle }}
				</div>
				<v-divider class="mb-4"></v-divider>
				<slot> </slot>
			</v-col>
		</v-row>
	</v-container>
</template>

<style></style>

